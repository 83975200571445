import { useEffect, useState, useContext } from "react";
import { Alert } from "react-bootstrap";
import { loginWithOtp } from "../service/login";
import { useNavigate } from "react-router-dom";
import { loginWithMobile } from "../service/loginWithMobile";
import { LoaderContext } from "../context/LoaderContext";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Footer from "../layout/Footer";
import { loginWithCoupon } from "../service/loginWithCoupon";
import { ThemeContext } from "../context/ThemeProvider";
import { toast } from "react-toastify";

export default function Login() {
  const [otp, setOtp] = useState();
  // const [countdown, setCountDown] = useState("");
  const [submitBtn, setSubmitBtn] = useState(true);
  const { theme, setTheme } = useContext(ThemeContext);
  const [alert, setAlert] = useState({ state: false, variant: "", text: "" });
  const [isOtpSent, SetIsOtpSent] = useState(false);
  const [mobile, setMobile] = useState(false);
  const [mobileAlert, setMobileAlert] = useState({ state: false });
  const [errorModal, setErrorModal] = useState(false);
  const [couponUser, setCouponUser] = useState(null);
  const [isWillingWithCoupon, setIsWillingWithCoupon] = useState(false);

  const navigate = useNavigate();

  const { setLoading } = useContext(LoaderContext);

  useEffect(() => {
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = async (e) => {
    setOtp({ ...otp, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    console.log(otp);
  }, [otp]);

  const handleMobileInput = async (e) => {
    if (validateMobile(e.target.value)) {
      setMobile(e.target.value);
      setMobileAlert({ state: false, text: "" });
    } else {
      const input = e.target;
      input.value = input.value.replace(/[^0-9]/g, "");
    }
  };

  const validateMobile = (value) => {
    value.replace(/\D/g, "");
    if (value[0] < 5) {
      setMobileAlert({
        state: true,
        text: "Mobile number must not start with 0,1,2,3,4 and 5.",
      });
      return false;
    } else if (/^\d{10}$/.test(value)) {
      return true;
    } else {
      setMobileAlert({
        state: true,
        text: "Mobile number must contain 10 digits !",
      });
      return false;
    }
  };

  // useEffect(() => {
  //     if (mobile.length === 10 && mobile > 5000000000) {
  //         setMobileAlert({ state: false });
  //     } else {
  //         setMobileAlert({ state: true, text: "Mobile number must contain 10 digits." });
  //     }
  // }, [mobile])

  const handleSubmit = async () => {
    if (submitBtn) {
      setLoading(true);
      const { success, accessToken } = await loginWithOtp(
        parseInt(`${otp?.otp}`),
        mobile
      );
      if (success) {
        setLoading(false);
        setAlert({
          state: true,
          variant: "success",
          text: "OTP Submitted Successfully",
        });
        navigate("/");
        localStorage.setItem("token", accessToken);
      } else {
        setLoading(false);
        setAlert({ state: true, variant: "danger", text: "OTP did not match" });
      }
    }
  };

  const handleOtpSent = async () => {
    const specificMobileNumber = "9253606666";

    if (mobile === specificMobileNumber && !mobileAlert.state) {
      try {
        setLoading(true);
        const response = await loginWithMobile(mobile);
        // console.log(response);
        setLoading(false);
        if (response.data.success === true) {
          SetIsOtpSent(true);
        } else {
          toast.error("Invalid mobile number");
        }
      } catch (err) {
        toast.error("Please provide mobile number");
      }
    } else {
      toast.error("Invalid mobile number");
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setAlert({ state: false, variant: "", text: "" });
    }, 5000);
  }, [alert]);

  function clickEvent(first, last) {
    if (first.value.length) {
      document.getElementById(last).focus();
    }
  }

  // var countDownDate = new Date().getTime() + (90 * 1000);

  // var x = setInterval(function () {

  //     var now = new Date().getTime();

  //     var distance = countDownDate - now;
  //     var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  //     var seconds = Math.floor((distance % (1000 * 60)) / 1000);

  //     if (minutes < 10 && seconds < 10) {
  //         setCountDown(`0${minutes} : 0${seconds}`);
  //     } else if (minutes < 10) {
  //         setCountDown(`0${minutes} : ${seconds}`);
  //     } else if (seconds < 10) {
  //         setCountDown(`${minutes} : 0${seconds}`);
  //     } else {
  //         setCountDown(`${minutes} : ${seconds}`);
  //     }
  // })

  const handleInputChange = async (e) => {
    setCouponUser({ ...couponUser, [e.target.name]: e.target.value });
    console.log(e.target.name, e.target.value);
  };

  const handleLoginWithCoupon = async (e) => {
    if (
      couponUser &&
      Object.values(couponUser).length > 1 &&
      isWillingWithCoupon
    ) {
      setLoading(true);
      const response = await loginWithCoupon(couponUser);
      setLoading(false);
      console.log(response);
      if (response?.data?.success) {
        navigate("/");
        localStorage.setItem("token", response?.data?.accessToken);
      } else if (response.status !== 200) {
        console.log("alert");
        toast.error("Invalid Credentials");
      }
    } else {
      if (isWillingWithCoupon) toast.error("Please fill all the fields");
    }
  };

  useEffect(() => {
    console.log(couponUser);
  }, [couponUser]);

  return (
    <>
      <div className="container">
        {alert.state && (
          <Alert variant={alert.variant || ""} dismissible>
            {alert.text || ""}
          </Alert>
        )}
        <img
          style={{ margin: "0 auto 40px" }}
          className="login-logo"
          src={theme ? "/images/darkAmazon.png" : "/images/darkAmazon.png"}
          alt=""
        />
        {/* <img style={{ margin: "0 auto 40px" }} className="login-logo" src="/images/almond-logo.png" alt="" /> */}
        {!isWillingWithCoupon && (
          <>
            {!isOtpSent ? (
              <div className="mobile-container">
                {/* <h2>Please Enter your mobile number</h2> */}
                <div className="userInput">
                  <input
                    className="phone-input"
                    type="tel"
                    name="mobile"
                    maxLength={10}
                    onChange={handleMobileInput}
                    placeholder="Enter your mobile"
                  />
                  {mobileAlert.state && (
                    <div className="mobile-alert-label primary">
                      {" "}
                      {mobileAlert.text}
                    </div>
                  )}
                </div>
                <button
                  className={
                    !mobileAlert.state ? "btn-submit" : "faded btn-submit"
                  }
                  onClick={handleOtpSent}
                >
                  Send OTP
                </button>
              </div>
            ) : (
              <div className="otp-container">
                <h1>Verify OTP</h1>
                <h3>
                  We have sent you an One Time Password to your mobile or email
                </h3>
                {/* <div className="userInput">
                        <input className="otp-value" type="password" id='ist' maxlength="1" onKeyUp={(e) => { clickEvent(e.target, 'sec') }} onChange={handleChange} />
                        <input className="otp-value" type="password" id="sec" maxlength="1" onKeyUp={(e) => { clickEvent(e.target, 'third') }} onChange={handleChange} />
                        <input className="otp-value" type="password" id="third" maxlength="1" onKeyUp={(e) => { clickEvent(e.target, 'fourth') }} onChange={handleChange} />
                        <input className="otp-value" type="password" id="fourth" maxlength="1" onChange={handleChange} />
                    </div> */}
                <input
                  className="phone-input"
                  type="text"
                  maxLength={4}
                  onChange={handleChange}
                  name="otp"
                  style={{ margin: "30px auto" }}
                  placeholder="Enter your code"
                />
                <button
                  className={submitBtn ? "btn-submit" : "faded btn-submit"}
                  onClick={handleSubmit}
                >
                  Confirm
                </button>
              </div>
            )}
          </>
        )}

        {/* {Login with Coupon Form} */}

        {
          //   <div className="mc-login-with-coupon-container">
          //     {!isWillingWithCoupon && (
          //       <div className="mc-or-container">
          //         <div className="mc-underline"></div>
          //         <div className="mc-or">OR</div>
          //         <div className="mc-underline"></div>
          //       </div>
          //     )}
          //     {isWillingWithCoupon && (
          //       <>
          //         <input
          //           className="phone-input mc-input-field"
          //           type="text"
          //           name="coupon_code"
          //           maxLength={16}
          //           onChange={handleInputChange}
          //           placeholder="Enter your coupon code"
          //         />
          //         <input
          //           className="phone-input mc-input-field"
          //           type="number"
          //           name="almond_pin"
          //           maxLength={4}
          //           onChange={handleInputChange}
          //           placeholder="Enter your coupon pin"
          //         />
          //       </>
          //     )}
          //     <button
          //       className={!mobileAlert.state ? "btn-submit" : "faded btn-submit"}
          //       onClick={() => {
          //         setIsWillingWithCoupon(true);
          //         handleLoginWithCoupon();
          //       }}
          //     >
          //       Login
          //     </button>
          //     {/* {isWillingWithCoupon && <><div className="mc-or-container">
          //             <div className="mc-underline"></div>
          //             <div className="mc-or">OR</div>
          //             <div className="mc-underline"></div>
          //         </div>
          //             <button className={!mobileAlert.state ? "btn-submit" : "faded btn-submit"} onClick={() => { setIsWillingWithCoupon(false) }}>Login With Mobile</button>
          //         </>} */}
          //   </div>
        }

        {
          <Modal show={errorModal} centered>
            <img className="mc-check-gif" src="images/cross.gif" alt="" />
            <div className="mc-redeemed-coupon-details">
              <h3 className="mc-coupon-redeemed-text">Invalid Credentials !</h3>
              {/* <p>Invalid Credentials !</p> */}
              <div className="mc-product-desc-container description"></div>
            </div>

            {/* <Modal.Body></Modal.Body> */}
            <Modal.Footer className="mc-modal-footer">
              <Button
                className="mc-coupon-redeem-button"
                variant="danger"
                onClick={() => {
                  setErrorModal(false);
                }}
              >
                Ok, got it!
              </Button>
            </Modal.Footer>
          </Modal>
        }
      </div>
    </>
  );
}
